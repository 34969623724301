import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Sir Fool" link="https://www.google.com" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A next gen platformer heavily inspired by Super Ghouls And Ghosts,
  a SNES launch title! Whoa man, retro!
    </ProjectCard>
    <ProjectCard title="Stuff for stuff" link="https://www.google.com" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  This is where you can find more stuff.
    </ProjectCard>
    <ProjectCard title="More stuff for stuff" link="https://www.google.com" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  A solution with the createSchemaCustomization API.
    </ProjectCard>
    <ProjectCard title="Again more stuff" link="https://www.google.com" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  No words can define the scope of this project.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      